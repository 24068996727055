import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import BaseApi from "../../../../../Http/BaseApi";
import Button from "../../../../../Components/Utils/Button";
import Modal from "../../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../../utils/TableComponent";
import { toast } from "react-toastify";

const BookChapter = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [bookChapters, setBookChapters] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited
  const [initialValues, setInitialValues] = useState({
    id: "",
    title: "",
    book_name: "",
    book_type: "",
    book_type_others: "",
    editors: "",
    author_names: "",
    publisher: "",
    month: "",
    year: "",
    isbn: "",
    indexed_in: "",
    file_url: "",
  });
  const headers = {
    title: {
      label: "Title of the Book Chapter",
      path: "title",
    },
    book_name: {
      label: "Book Name",
      path: "book_name",
    },
    book_type: {
      label: "Book Type",
      path: "book_type",
    },
    book_type_others: {
      label: "Others",
      path: "book_type_others",
    },
    publisher: {
      label: "Publisher",
      path: "publisher",
    },
    month: {
      label: "Month of Publication",
      path: "monthName",
    },
    year: {
      label: "Year of Publication",
      path: "year",
    },
    author_names: {
      label: "Author Names: (As per Book)",
      path: "author_names",
    },
    isbn: {
      label: "ISBN",
      path: "isbn",
    },
    editors: {
      label: "Editor(s)",
      path: "editors",
    },
    indexed_in: {
      label: "Indexed In",
      path: "indexed_in",
    },
    file_path: {
      label: "URL",
      path: "file_path",
    },
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("This field is required!"),
    book_name: Yup.string().required("This field is required!"),
    book_type: Yup.string().required("This field is required!"),
    book_type_others: Yup.string().when("book_type", {
      is: "Others",
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().nullable(),
    }),
    publisher: Yup.string().required("This field is required!"),
    month: Yup.string().required("This field is required!"),
    // year: Yup.string().required("Required"),
    year: Yup.number()
      .typeError("Year must be a valid year")
      .required("This field is required!")
      .min(1980, "Year must be at least 1980")
      .max(new Date().getFullYear(), `Year can't be in the future`),
    author_names: Yup.string().required("This field is required!"),
    isbn: Yup.string().required("This field is required!"),
    editors: Yup.string().required("This field is required!"),
    file_url: Yup.mixed()
      .required("This field is required!")

      .test("fileSize", "File size must be less than 500KB", (file) => {
        if (!file) return true; // If no file, pass validation
        return file.size <= 500 * 1024;
      })
      .test("fileType", "Only PDF or image files are allowed", (file) => {
        if (!file) return true; // If no file, pass validation
        return ["application/pdf", "image/jpeg", "image/png"].includes(
          file.type
        );
      }),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/book-chapters");
      setBookChapters(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        const formData = new FormData();
        for (let key in values) {
          formData.append(key, values[key]);
        }
        formData.append("_method", "PUT");
        await BaseApi().post(`/profile/book-chapters/${editId}`, formData);

        toast.success(`Book Chapters Updated Scuccessfully`);

        setEditId(null);
        handleCancel();
      } else {
        const formData = new FormData();
        for (let key in values) {
          formData.append(key, values[key]);
        }
        await BaseApi().post("/profile/book-chapters", formData);

        toast.success(`Book Chapters Added Scuccessfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = bookChapters.find(
        (BookChapter) => BookChapter.id === id
      );
      setInitialValues({
        id: editableData.id,
        book_name: editableData.book_name,
        book_type: editableData.book_type,
        book_type_others: editableData.book_type_others,
        title: editableData.title,
        publisher: editableData.publisher,
        month: editableData.month,
        year: editableData.year,
        author_names: editableData.author_names,
        isbn: editableData.isbn,
        editors: editableData.editors,
        file_url: editableData.file_url,
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      title: "",
      book_name: "",
      book_type: "",
      book_type_others: "",
      publisher: "",
      month: "",
      year: "",
      author_names: "",
      isbn: "",
      editors: "",
      file_url: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      title: "",
      book_name: "",
      book_type: "",
      book_type_others: "",
      publisher: "",
      month: "",
      year: "",
      author_names: "",
      isbn: "",
      editors: "",
      file_url: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;

    try {
      await BaseApi().delete(`/profile/book-chapters/${id}`);
      toast.success(`Book Chapters Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <div className='space-y-5'>
        <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50'>
          <h1>Book Chapters :</h1>
          <Button
            type='add'
            value='Add more'
            icon={"PlusIcon"}
            onClick={handleAddMore}
          />
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        <div className='p-2 border bg-blue-50'>
          <TableComponent
            headers={headers}
            data={bookChapters}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default BookChapter;
