import React, { useEffect, useRef } from "react";
import { Formik, Form, useFormikContext } from "formik";
import Button from "../../../../Components/Utils/Button";
import CustomSelect from "../../../../Components/Utils/CustomSelect";
import Input from "../../../../Components/Utils/Input";
import { XIcon } from "@heroicons/react/outline";
import Upload from "../../../../Components/Utils/Upload";

function SyncUrlAndFileUrl() {
  const { values, setFieldValue } = useFormikContext(); // Access Formik values and setFieldValue

  useEffect(() => {
    if (values.url.length > 0) {
      setFieldValue("file_url", "");
    }
    if (values.file_url) {
      setFieldValue("url", "");
    }
  }, [values.file_url, values.url, setFieldValue]);

  return null; // This component doesn't render anything, just used for side-effects
}

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  console.log("Form is called with initialValues:", initialValues);
  const formikRef = useRef(null);
  const indexedInTypes = [
    { id: 1, name: "Scopus" },
    { id: 2, name: "Web of Science(core)" },
    { id: 3, name: "Web of Science (others)" },
    { id: 4, name: "Both Scopus and WOS (core)" },
    { id: 7, name: "Both Scopus and WOS (others)" },
    { id: 5, name: "UGC care list I" },
    { id: 6, name: "Others" },
  ];
  const correspondingPrincipalTypes = [
    { id: 1, name: "Yes" },
    { id: 2, name: "No" },
  ];
  const fundingSourceTypes = [
    { id: 1, name: "Yes" },
    { id: 2, name: "No" },
  ];

  const journalTypeTypes = [
    { id: 1, name: "National" },
    { id: 2, name: "International" },
  ];

  const monthTypes = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  return (
    <>
      <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
        Research Publication
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue }) => {
          // useEffect(() => {
          //   if (values.url.length > 0) {
          //     setFieldValue("file_url", "");
          //   }
          //   if (values.file_url) {
          //     setFieldValue("url", "");
          //   }
          // }, [values.file_url, values.url, setFieldValue]);
          return (
            <Form className='grid grid-cols-2 gap-3 p-5 '>
              <Input
                type='textarea'
                name='title'
                label={
                  <div className='flex items-center'>
                    Title of the Paper
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
              />
              <Input
                type='textarea'
                name='journal'
                label={
                  <div className='flex items-center'>
                    Journal Name
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
              />
              <CustomSelect
                defaultInputValue={initialValues.journal_type}
                options={journalTypeTypes}
                name='journal_type'
                label={
                  <div className='flex items-center'>
                    Journal Type
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
                isSearchable={true}
                getOptionValue={(option) => `${option.id}`}
                getOptionLabel={(option) => `${option.name}`}
                onChange={async (selectedOption) => {
                  formikRef.current?.setFieldValue(
                    "journal_type",
                    selectedOption.name
                  );
                }}
              />
              <Input
                type='text'
                name='issn'
                label={
                  <div className='flex items-center'>
                    ISSN
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
              />
              <CustomSelect
                defaultInputValue={
                  monthTypes.find((month) => month.id == initialValues.month)
                    ?.name
                }
                options={monthTypes}
                name='month'
                label={
                  <div className='flex items-center'>
                    Month of Publication
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
                isSearchable={true}
                getOptionValue={(option) => `${option.id}`}
                getOptionLabel={(option) => `${option.name}`}
                onChange={async (selectedOption) => {
                  formikRef.current?.setFieldValue("month", selectedOption.id);
                }}
              />
              <Input
                type='text'
                name='year'
                label={
                  <div className='flex items-center'>
                    Year of Publication
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
              />
              <CustomSelect
                defaultInputValue={initialValues.corresponding_principal}
                options={correspondingPrincipalTypes}
                name='corresponding_principal'
                label={
                  <div className='flex items-center'>
                    Are you corresponding/principal author?
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
                isSearchable={true}
                getOptionValue={(option) => `${option.id}`}
                getOptionLabel={(option) => `${option.name}`}
                onChange={async (selectedOption) => {
                  formikRef.current?.setFieldValue(
                    "corresponding_principal",
                    selectedOption.name
                  );
                }}
              />

              <Input
                type='text'
                name='author_names'
                placeholder='Enter Author Names separated by commas, if more than one author'
                label={
                  <div className='flex items-center'>
                    Author Names: (As appeared in the article)
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
              />
              {!values.file_url && (
                <div>
                  <Input
                    type='text'
                    name='url'
                    label={<div className='flex items-center'>URL/DOI</div>}
                  />
                  <small className='text-red-600'>
                    In case of DOI, please write the full URL such as
                    <span className='text-blue-500'>
                      {" "}
                      https://doi.org/10.1093/ajae/aaq063
                    </span>
                  </small>
                </div>
              )}

              {values.url.length == 0 && (
                <div className='space-y-1'>
                  <label>
                    <div className='flex items-center'>
                      If URL/DOI not available, then please upload the first
                      page of the published paper
                    </div>
                  </label>
                  <Upload
                    label={
                      <div className='flex items-center'>
                        <ul className='text-red-600'>
                          <li>File upload max 500 KB</li>
                          <li>Image/PDF only</li>
                        </ul>
                      </div>
                    }
                    type='file'
                    name='file_url'
                    onClear={() => {
                      formikRef.current.setFieldValue("file_url", "");
                    }}
                    onChange={(event) => {
                      let myFile = event.currentTarget.files[0];
                      formikRef.current.setFieldValue("file_url", myFile);
                    }}
                  />
                </div>
              )}

              <SyncUrlAndFileUrl />
              <Input
                type='text'
                name='volume'
                label={
                  <div className='flex items-center'>
                    Volume
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
              />
              <Input
                type='text'
                name='issue'
                label={<div className='flex items-center'>Issue</div>}
              />
              <Input
                type='text'
                name='impact_factor'
                label='Impact Factor (Thomson Reuters only)'
              />
              <Input type='text' name='page_no' label='Page No/Paper No' />
              <Input type='text' name='editor_name' label='Editor Name' />
              <Input type='text' name='publisher_name' label='Publisher Name' />

              <CustomSelect
                defaultInputValue={initialValues.indexed_in}
                options={indexedInTypes}
                name='indexed_in'
                label={
                  <div className='flex items-center'>
                    Indexed in
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
                isSearchable={true}
                getOptionValue={(option) => `${option.id}`}
                getOptionLabel={(option) => `${option.name}`}
                onChange={async (selectedOption) => {
                  console.log("Selected option:", selectedOption);
                  formikRef.current?.setFieldValue(
                    "indexed_in",
                    selectedOption.name
                  );
                  console.log(
                    "Formik values after update:",
                    formikRef.current?.values
                  );
                }}
              />

              {/* If indexed_in select Others then show this field otherwise hide it */}

              {values.indexed_in === "Others" && (
                <Input
                  type='text'
                  placeholder='Please specify'
                  name='indexed_in_others'
                  label={
                    <div className='flex items-center'>
                      Indexed In Others
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
              )}

              <CustomSelect
                defaultInputValue={initialValues.funding_source}
                options={fundingSourceTypes}
                name='funding_source'
                label={
                  <div className='flex items-center'>
                    Funding Source?
                    <span className='ml-1 text-red-600'>*</span>
                  </div>
                }
                isSearchable={true}
                getOptionValue={(option) => `${option.id}`}
                getOptionLabel={(option) => `${option.name}`}
                onChange={async (selectedOption) => {
                  formikRef.current?.setFieldValue(
                    "funding_source",
                    selectedOption.name
                  );
                }}
              />

              {values.funding_source === "Yes" && (
                <Input
                  type='text'
                  name='funding_source_details'
                  label={
                    <div className='flex items-center'>
                      Funding Source Details
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
              )}

              <div className='flex justify-between col-span-2 mt-10'>
                <button
                  className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                  onClick={onCancel}
                >
                  <XIcon className='w-5 h-5 mr-2' /> Cancel
                </button>
                {isEditMode ? (
                  <Button type='submit' value='Save' icon={"SaveIcon"} />
                ) : (
                  <Button type='submit' value='Update' icon={"SaveIcon"} />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default FormPage;
