import React, { useEffect, useRef, useState } from "react";
import BasicInfo from "./Components/BasicInfo/BasicInfo";
import Expertise from "./Components/Expertise/Expertise";
import EduQualification from "./Components/EducationalQualification/EduQualification";
import Experience from "./Components/Experience/Experience";
import JournalPublication from "./Components/JournalPublication/JournalPublication";
import ConferenceSeminar from "./Components/ConferenceSeminar/ConferenceSeminar";
import Project from "./Components/Project/Project";
import LecturesTalk from "./Components/LecturesTalk/LecturesTalk";
import AcademicVisits from "./Components/AcademicVisit/AcademicVisit";
import CorporateLife from "./Components/CorporateLife/CorporateLife";
import MentorMentee from "./Components/MentorMentee/MentorMentee";
import BodiesOrganization from "./Components/BodiesOrganization/BodiesOrganization";
import FIPActivitiesParticipation from "./Components/FIP-ActivitiesParticipation/FIPActivitiesParticipation";
import StartupDevelopment from "./Components/StartupDevelopment/StartupDevelopment";
import BooksAndChapter from "./Components/BooksAndChapter/BooksAndChapter";
import ConsultancyExternalService from "./Components/ConsultancyExternalService/ConsultancyExternalService";
import HonorsAward from "./Components/HonorsAward/HonorsAward";
import Collaboration from "./Components/Collaboration/Collaboration";
import Committee from "./Components/Committee/Committee";
import { FaAngleLeft, FaAngleRight, FaCheck } from "react-icons/fa";
import PhdGuidance from "./Components/PhdGuidance/PhdGuidance";
import BaseApi from "../../Http/BaseApi";
import { useDispatch, useSelector } from "react-redux";
import { FaEye, FaGlobe } from "react-icons/fa6";
import { Link } from "react-router-dom";
import WorkshopTraining from "./Components/WorkshopTraining/WorkshopTraining";
import Loader from "./utils/Loader";
import ProgrammeOrganized from "./Components/ProgrammeOrganized/ProgrammeOrganized";
import EContentDevelopment from "./Components/EContentDevelopment/EContentDevelopment";
import IPR from "./Components/IPR/IPR";
import ExtensionActivitie from "./Components/ExtensionActivitie/ExtensionActivitie";
import Support from "./Components/Support/Support";
import StudentsProjectDetail from "./Components/StudentsProjectDetail/StudentsProjectDetail";

const FacultyProfile = () => {
  const [tabs, setTabs] = useState([
    {
      id: 0,
      label: "Basic Information",
      content: <BasicInfo />,
    },
    {
      id: 1,
      label: "Expertise",
      content: <Expertise />,
    },
    {
      id: 2,
      name: "educationalQualification",
      label: "Educational Qualifications",
      content: <EduQualification />,
    },
    {
      id: 3,
      name: "experience",
      label: "Experience",
      content: <Experience />,
    },
    {
      id: 4,
      name: "journalPublication",
      label: "Research Publications",
      content: <JournalPublication />,
    },
    {
      id: 5,
      name: "conferenceSeminar",
      label: "Conference/Seminar Paper",
      content: <ConferenceSeminar />,
    },
    {
      id: 6,
      name: "booksAndChapter",
      label: "Books & Chapters",
      content: <BooksAndChapter />,
    },
    {
      id: 7,
      name: "ipr",
      label: "IPR",
      content: <IPR />,
    },

    {
      id: 8,
      name: "consultancyExternalService",
      label: "Consultancy/External Services",
      content: <ConsultancyExternalService />,
    },
    {
      id: 9,
      name: "project",
      label: "Projects",
      content: <Project />,
    },
    {
      id: 10,
      name: "honorsAward",
      label: "Honors and Awards",
      content: <HonorsAward />,
    },
    {
      id: 11,
      name: "lectureTalk",
      label: "Invited Lecture/Talk",
      content: <LecturesTalk />,
    },
    {
      id: 12,
      name: "academicVisit",
      label: "Academic Visit",
      content: <AcademicVisits />,
    },
    {
      id: 13,
      name: "phdGuidance",
      label: "Ph. D. Guidance",
      content: <PhdGuidance />,
    },
    {
      id: 14,
      name: "corporateLife",
      label: "Contribution to Corporate Life",
      content: <CorporateLife />,
    },
    // {
    //   id: 15,
    //   name: "mentorMentee",
    //   label: "Mentor-Mentee",
    //   content: <MentorMentee />,
    // },
    {
      id: 16,
      name: "collaboration",
      label: "Collaborations",
      content: <Collaboration />,
    },
    {
      id: 17,
      name: "bodiesOrganization",
      label: "Memberships in Professional Bodies/Organization",
      content: <BodiesOrganization />,
    },
    {
      id: 18,
      name: "committee",
      label: "Memberships in Significant Committees:",
      content: <Committee />,
    },
    {
      id: 19,
      name: "fipActivitiesParticipation",
      label: "FIP activities/Participation in FDP",
      content: <FIPActivitiesParticipation />,
    },
    {
      id: 20,
      name: "startupDevelopment",
      label: "Startup",
      content: <StartupDevelopment />,
    },
    {
      id: 21,
      name: "workshopTraining",
      label: "Participation in Programme",
      content: <WorkshopTraining />,
    },
    {
      id: 22,
      name: "programmeOrganized",
      label: "Programme Organized",
      content: <ProgrammeOrganized />,
    },
    {
      id: 23,
      name: "eContentDevelopment",
      label: "E-Content Development",
      content: <EContentDevelopment />,
    },
    {
      id: 24,
      name: "extensionActivitie",
      label: "Outreach/Extension Activities",
      content: <ExtensionActivitie />,
    },
    {
      id: 25,
      name: "support",
      label: "National/International Fellowship/Financial Support",
      content: <Support />,
    },
    {
      id: 26,
      name: "studentsProjectDetail",
      label: "Students' Project Details",
      content: <StudentsProjectDetail />,
    },
  ]);
  const [activeTab, setActiveTab] = useState(0); // Set initial activeTab to 0
  const [searchTerm, setSearchTerm] = useState("");
  const scrollContainerRef = useRef(null);
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProfileCounts(authData.user.id);
  }, [dispatch, authData]);

  const getProfileCounts = async (id) => {
    try {
      const data = await BaseApi().get(`profile/${id}/counts`, {});
      const updatedTabs = tabs.map((tab) => {
        if (tab.name in data.data) {
          tab.activity = data.data[tab.name];
        }
        return tab;
      });
      setTabs(updatedTabs);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Stop loader after data is fetched
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 250; // Adjust this value as needed
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 250; // Adjust this value as needed
    }
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTabs = tabs.filter((tab) =>
    tab.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative max-w-full mx-5">
      {loading && <Loader />}
      {!loading && (
        <>
          {/* search box */}
          <div className="flex items-center justify-between mb-2">
            <div>
              <Link
                to="/view-profile"
                className="px-3 py-2 mr-5 text-sm font-semibold text-green-700 bg-gray-200 rounded-full "
              >
                <FaEye className="inline" /> Own View
              </Link>
              <Link
                to="/view-profile"
                className="px-3 py-2 mr-5 text-sm font-semibold text-purple-700 bg-gray-200 rounded-full "
              >
                <FaCheck className="inline" /> Publish on Website
              </Link>
              <Link
                // to='/view-profile'
                to="#"
                className="px-3 py-2 mr-5 text-sm font-semibold text-red-700 bg-gray-200 rounded-full cursor-not-allowed "
              >
                <FaCheck className="inline" /> Submit to IQAC
              </Link>
              <Link
                to="/faculty-profile-website"
                target="_blank"
                className="px-3 py-2 mr-5 text-sm font-semibold text-blue-700 bg-gray-200 rounded-full "
              >
                <FaGlobe className="inline" /> Public Profile (Website View)
              </Link>
              {/* <Link
                to='/faculty-profile-website?view=iqac'
                className='px-3 py-2 mr-10 text-sm font-semibold text-orange-500 bg-gray-200 rounded-full '
              >
                <FaGlobe className='inline' /> Faculty Profile (IQAC View)
              </Link> */}
            </div>

            <input
              type="text"
              placeholder="Search tabs..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="p-2 mt-2 text-sm text-gray-700 placeholder-gray-500 bg-gray-200 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
          </div>

          <div className="flex items-center mb-4">
            <button
              className="p-2 ml-2 text-blue-600 bg-blue-100 rounded-full"
              onClick={scrollLeft}
            >
              <FaAngleLeft />
            </button>
            <div
              ref={scrollContainerRef}
              className="flex w-full overflow-x-auto border-b border-gray-200 scroll-smooth"
            >
              {filteredTabs.map((tab) => (
                <button
                  key={tab.id}
                  className={`whitespace-nowrap focus:outline-none py-2 px-4 mx-2 my-2   flex items-center ${
                    activeTab === tab.id
                      ? "border-b-2 border-blue-500 text-blue-500"
                      : "text-gray-500"
                  }`}
                  onClick={() => handleTabClick(tab.id)}
                >
                  <span className="mr-2">
                    {tab.label}
                    {tab.activity > 0 && (
                      <span className="px-2 py-1 ml-2 text-xs text-blue-700 bg-blue-100 rounded-full">
                        {tab.activity}
                      </span>
                    )}
                  </span>
                </button>
              ))}
            </div>
            <button
              className="p-2 ml-2 text-blue-600 bg-blue-100 rounded-full"
              onClick={scrollRight}
            >
              <FaAngleRight />
            </button>
          </div>

          <div className="mt-4">
            {filteredTabs.map((tab) => (
              <div key={tab.id}>
                {activeTab === tab.id && (
                  <>
                    <p>{tab.content}</p>
                  </>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FacultyProfile;
