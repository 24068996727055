import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import TableComponent from "../../utils/TableComponent";
import BaseApi from "../../../../Http/BaseApi";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { XIcon } from "@heroicons/react/outline";
import { FaCheck, FaRegEdit } from "react-icons/fa";

const JournalPublication = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [journalPublications, setJournalPublications] = useState([]); // Array to store qualifications

  const [editId, setEditId] = useState(null); // Index of the qualification being edited

  const [csvData, setCsvData] = useState([]); // To hold parsed CSV data
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false); // To control bulk upload modal visibility
  const [checkedRows, setCheckedRows] = useState({}); // To hold checked rows
  const [csvDataStatus, setCsvDataStatus] = useState([]);

  const [isProcessing, setIsProcessing] = useState(false); // To handle process button disabling
  const [selectAll, setSelectAll] = useState(false); // To handle Select All functionality
  const [isProcessed, setIsProcessed] = useState(false); // To handle processed

  const requiredColumns = [
    "Authors",
    "Title",
    "Publication",
    "Volume",
    "Number",
    "Pages",
    "Year",
    "Publisher",
  ];

  const [initialValues, setInitialValues] = useState({
    id: "",
    title: "",
    journal: "",
    journal_type: "",
    issn: "",
    month: "",
    year: "",
    corresponding_principal: "",
    funding_source: "",
    funding_source_details: "",
    author_names: "",
    url: "",
    file_url: "",
    volume: "",
    issue: "",
    impact_factor: "",
    page_no: "",
    editor_name: "",
    publisher_name: "",
    indexed_in: "",
    indexed_in_others: "",
  });
  const headers = {
    title: { label: "Title of the Paper", path: "title" },
    journal: { label: "Journal Name", path: "journal" },
    journal_type: { label: "Journal Type", path: "journal_type" },
    issn: { label: "ISSN", path: "issn" },
    month: { label: "Month of Publication", path: "monthName" },
    year: { label: "Year of Publication", path: "year" },
    corresponding_principal: {
      label: "Are you corresponding/principal author?",
      path: "corresponding_principal",
    },
    funding_source: {
      label: "Funding Sources",
      path: "funding_source",
    },
    funding_source_details: {
      label: "Funding Source Details",
      path: "funding_source_details",
    },
    author_names: {
      label: "Author Names: (As appeared in the article)",
      path: "author_names",
    },
    url: { label: "URL/DOI", path: "url" },
    // file_url: {
    //   label: "Upload the first page of the published paper",
    //   path: "file_url",
    // },

    volume: { label: "Volume", path: "volume" },
    issue: { label: "Issue", path: "issue" },
    impact_factor: {
      label: "Impact Factor (Thomson Reuters only) (Optional)",
      path: "impact_factor",
    },
    page_no: { label: "Page No/Paper No (Optional)", path: "page_no" },
    editor_name: { label: "Editor Name", path: "editor_name" },
    publisher_name: {
      label: "Publisher Name (Optional)",
      path: "publisher_name",
    },
    indexed_in: { label: "Indexed in", path: "indexed_in" },
    indexed_in_others: {
      label: "Indexed In others",
      path: "indexed_in_others",
    },
  };

  Yup.addMethod(Yup.string, "nullableToEmpty", function () {
    return this.transform((value, originalValue) => {
      return originalValue == null ? "" : value;
    });
  });

  const validationSchema = Yup.object({
    title: Yup.string().required("This field is required!"),
    journal: Yup.string().required("This field is required!"),
    journal_type: Yup.string().required("This field is required!"),
    issn: Yup.string().required("This field is required!"),
    month: Yup.string().required("This field is required!"),
    year: Yup.number()

      .typeError("Year must be a valid year")
      .required("This field is required!")
      .min(1980, "Year must be at least 1980")
      .max(new Date().getFullYear(), `Year can't be in the future`),
    author_names: Yup.string().required("This field is required!"),

    volume: Yup.string().required("This field is required!"),
    // issue: Yup.string().required("Issue is required"),
    indexed_in: Yup.string().required("This field is required!"),
    indexed_in_others: Yup.string().when("indexed_in", {
      is: "Others", // Check if 'indexed_in' is equal to 'Others'
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().nullable(), // Optional: can leave this out if no other condition is needed
    }),
    corresponding_principal: Yup.string().required("This field is required!"),
    funding_source: Yup.string().required("This field is required!"),
    funding_source_details: Yup.string().when("funding_source", {
      is: "Yes", // Check if 'funding_source' is equal to 'Others'
      then: Yup.string().required("This field is required!"),
      otherwise: Yup.string().nullable(), // Optional: can leave this out if no other condition is needed
    }),
    url: Yup.string().nullable(), // Allow URL to be empty, only file_url enforces one of them

    file_url: Yup.mixed().when("url", {
      is: (val) => !val || val.length === 0, // If no URL is provided
      then: Yup.mixed()
        .required(
          "Either URL/DOI or the first page of the published paper is required!"
        )
        .test("fileSize", "File size must be less than 500KB", (file) => {
          if (!file) return true; // Pass when file is not provided
          return file.size <= 500 * 1024;
        })
        .test("fileType", "Only PDF or image files are allowed", (file) => {
          if (!file) return true; // Pass when file is not provided
          return ["application/pdf", "image/jpeg", "image/png"].includes(
            file.type
          );
        }),
      otherwise: Yup.mixed(), // File upload is optional if URL is provided
    }),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/journal-publications");
      setJournalPublications(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (editId) {
        const formData = new FormData();
        for (let key in values) {
          formData.append(key, values[key]);
        }

        formData.append("_method", "PUT");
        await BaseApi().post(
          `/profile/journal-publications/${editId}`,
          formData
        );
        toast.success(`Research Publication Updated Successfully`);
        setEditId(null);
        handleCancel();
      } else {
        const formData = new FormData();
        for (let key in values) {
          formData.append(key, values[key]);
        }

        await BaseApi().post("/profile/journal-publications", formData);
        toast.success(`Research Publication Added Successfully`);
        handleCancel();
      }
      resetForm();
      getData();
    } catch (error) {
      console.error(error);

      // Check if the error response contains a message about duplicate titles
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const errorMessage = error.response.data.message;

        // Check for duplicate title error message
        if (errorMessage.toLowerCase().includes("duplicate")) {
          toast.error(
            "Duplicate title detected. Please use a different title."
          );
        } else {
          // Handle other error messages
          toast.error(errorMessage);
        }
      } else {
        // Generic error handler if no specific error message is available
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  const handleEdit = (id) => {
    if (id) {
      const editableData = journalPublications.find(
        (journalPublication) => journalPublication.id === id
      );
      setInitialValues({
        id: editableData.id,
        title: editableData.title,
        journal: editableData.journal ?? "",
        journal_type: editableData.journal_type ?? "",
        issn: editableData.issn ?? "",
        month: editableData.month ?? "",
        year: editableData.year ?? "",
        author_names: editableData.author_names ?? "",
        url: editableData.url ?? "",
        file_url: "",
        volume: editableData.volume ?? "",
        impact_factor: editableData.impact_factor ?? "",
        issue: editableData.issue ?? "",
        page_no: editableData.page_no ?? "",
        editor_name: editableData.editor_name ?? "",
        publisher_name: editableData.publisher_name ?? "",
        indexed_in: editableData.indexed_in ?? "",
        indexed_in_others: editableData.indexed_in_others ?? "",
        corresponding_principal: editableData.corresponding_principal ?? "",
        funding_source: editableData.funding_source ?? "",
        funding_source_details: editableData.funding_source_details ?? "",
      });
      setEditId(id);
      setIsEditing(true);
    }
  };

  const handleAddMore = () => {
    setInitialValues({
      id: "",
      title: "",
      journal: "",
      journal_type: "",
      issn: "",
      month: "",
      year: "",
      author_names: "",
      url: "",
      file_url: "",
      volume: "",
      impact_factor: "",
      issue: "",
      page_no: "",
      editor_name: "",
      publisher_name: "",
      indexed_in: "",
      indexed_in_others: "",
      corresponding_principal: "",
      funding_source: "",
      funding_source_details: "",
    });
    setIsAdding(true);
    setEditId(null);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setInitialValues({
      id: "",
      title: "",
      journal: "",
      journal_type: "",
      issn: "",
      month: "",
      year: "",
      author_names: "",
      url: "",
      file_url: "",
      volume: "",
      impact_factor: "",
      issue: "",
      page_no: "",
      editor_name: "",
      publisher_name: "",
      indexed_in: "",
      indexed_in_others: "",
      corresponding_principal: "",
      funding_source: "",
      funding_source_details: "",
    });
    setIsEditing(false);
    setIsAdding(false);

    setEditId(null); // Reset editIndexs
  };

  const handleDelete = async (id) => {
    // alert confirm dialog
    const confirm = window.confirm("Are you sure you want to delete?");
    if (!confirm) return;

    try {
      await BaseApi().delete(`/profile/journal-publications/${id}`);
      toast.success(`Research Publication Deleted Successfully`);
      getData();
    } catch (error) {
      alert(error.response.data.message);
      console.error(error);
    }
  };

  const handleBulkUpload = () => {
    setShowBulkUploadModal(true);
    setCsvData([]); // Reset CSV data
    setCsvDataStatus([]); // Reset CSV data status
    setCheckedRows({}); // Reset checked rows
    setSelectAll(false); // Reset select all
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const parsedData = results.data;
          const csvColumns = Object.keys(parsedData[0]);

          // Verify if all required columns are present
          const missingColumns = requiredColumns.filter(
            (col) => !csvColumns.includes(col)
          );

          if (missingColumns.length > 0) {
            toast.error(`Missing columns: ${missingColumns.join(", ")}`);
          } else {
            setCsvData(parsedData);
          }
        },
      });
    }
  };

  // Handle Select All / Deselect All
  const handleSelectAll = () => {
    const updatedCheckedRows = {};
    csvData.forEach((_, index) => {
      updatedCheckedRows[index] = !selectAll; // Toggle select all
    });
    setCheckedRows(updatedCheckedRows);
    setSelectAll(!selectAll); // Toggle the state
  };

  const handleCheckboxChange = (index) => {
    setCheckedRows({
      ...checkedRows,
      [index]: !checkedRows[index],
    });
  };

  const handleProcessUpload = async () => {
    if (
      !window.confirm("Are you sure you want to process the selected items?")
    ) {
      return;
    }

    const selectedData = csvData.filter((_, index) => checkedRows[index]);

    if (selectedData.length === 0) {
      alert("No rows selected. Please select at least one row to process.");
      return;
    }

    const statusMap = csvData.map((_, index) =>
      checkedRows[index] ? { status: "Pending", message: "" } : ""
    );
    setCsvDataStatus(statusMap); // Set initial "Pending" status for selected rows

    const batchSize = 10;
    setIsProcessing(true); // Disable process button

    for (let i = 0; i < selectedData.length; i += batchSize) {
      const batch = selectedData.slice(i, i + batchSize);

      try {
        const response = await BaseApi().post(
          `/profile/journal-publications/bulk-upload`,
          { data: batch }
        );

        const result = await response.data;

        // Ensure backend response is an array and includes status
        if (Array.isArray(result)) {
          // Update the status based on the result
          batch.forEach((row, batchIndex) => {
            const actualIndex = i + batchIndex;
            const statusRow = result.find((res) => res.Title === row.Title);

            if (statusRow && statusRow.status) {
              statusMap[actualIndex] = {
                status: statusRow.status,
                message: statusRow.message || "", // Show message if available
              }; // Set status as "Done" or "Error" with message
            } else {
              statusMap[actualIndex] = {
                status: "Error",
                message: "Unknown error",
              }; // If no status is found, mark it as "Error"
            }
          });

          setCsvDataStatus([...statusMap]); // Update the status map state
        } else {
          console.error("Unexpected response format:", result);
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        console.error("Error uploading publications:", error);

        // In case of a network error or response error, mark the batch as "Error"
        batch.forEach((_, batchIndex) => {
          const actualIndex = i + batchIndex;
          statusMap[actualIndex] = {
            status: "Error",
            message: "Network error or server issue",
          };
        });

        setCsvDataStatus([...statusMap]); // Update the status map state
      }
    }

    getData();
    setIsProcessing(false); // Re-enable process button
    setIsProcessed(true); // Set processed state
  };

  return (
    <>
      <div className='space-y-5'>
        <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50'>
          <h1>Research Publication :</h1>
          <div className='flex gap-2'>
            <Button
              icon={"UploadIcon"}
              type='button'
              value='Bulk Upload'
              onClick={handleBulkUpload}
            />
            <Button
              type='button'
              value='Add more'
              icon={"PlusIcon"}
              onClick={handleAddMore}
            />
          </div>
        </div>

        {(isEditing || isAdding) && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues} // Pass initial values for editing
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              isEditMode={isEditing}
            />
          </Modal>
        )}

        {showBulkUploadModal && (
          <Modal isOpen={showBulkUploadModal}>
            <div className='p-5'>
              <h1 className='flex items-center justify-center p-3 mt-2 text-xl font-normal text-center uppercase '>
                Upload Research Publication
              </h1>
              <p className='mb-4 text-sm text-center text-red-600'>
                Currently the system supports CSV file downloaded from Google
                Scholar. To know how to export your publications in CSV format
                from Google Scholar, please{" "}
                <a
                  className='font-semibold text-blue-600'
                  href='/googlescholarmanual.pdf'
                  target='_blank'
                >
                  click here
                </a>
              </p>
              <div className='flex justify-between p-3 mt-2 font-normal '>
                <div>
                  <h2>Upload CSV file (downloaded from Google Scholar)</h2>
                  <input
                    type='file'
                    accept='.csv'
                    onChange={handleFileChange}
                  />
                </div>
                {/* <div className='text-right text-blue-600'>
                  <a target='_blank' href='#'>
                    Click here to download CSV file from Google Scholar
                  </a>
                </div> */}
              </div>

              {csvData.length > 0 && (
                <div className='my-5'>
                  <hr />
                  <button
                    className='px-2 py-1 mt-2 text-sm bg-white border border-green-500 rounded-md shadow hover:bg-green-50 focus:outline-none '
                    onClick={handleSelectAll}
                  >
                    <FaCheck className='inline w-2 h-2 mr-2' />
                    {selectAll ? "Deselect All" : "Select All"}
                  </button>

                  <table className='w-full mt-4 text-sm border border-gray-300 table-auto'>
                    <thead>
                      <tr className='bg-gray-100'>
                        <th className='p-2 border border-gray-300'></th>
                        {requiredColumns.map((col) => (
                          <th key={col} className='p-2 border border-gray-300'>
                            {col}
                          </th>
                        ))}
                        <th className='p-2 border border-gray-300'>Status</th>
                        <th className='p-2 border border-gray-300'>
                          Message
                        </th>{" "}
                        {/* Add Message Column */}
                      </tr>
                    </thead>
                    <tbody>
                      {csvData.map((row, index) => (
                        <tr key={index} className='hover:bg-gray-50'>
                          <td className='p-2 border border-gray-300'>
                            <input
                              type='checkbox'
                              checked={checkedRows[index] || false}
                              onChange={() => handleCheckboxChange(index)}
                            />
                          </td>
                          {requiredColumns.map((col) => (
                            <td
                              key={col}
                              className='p-2 border border-gray-300'
                            >
                              {row[col]}
                            </td>
                          ))}
                          <td
                            className={`p-2 border border-gray-300 ${
                              csvDataStatus[index]?.status === "Done"
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            {csvDataStatus[index]?.status || "Pending"}{" "}
                            {/* Show Status */}
                          </td>
                          <td
                            className={`p-2 border border-gray-300 ${
                              csvDataStatus[index]?.status === "Done"
                                ? "text-green-500"
                                : "text-red-500"
                            }`}
                          >
                            {csvDataStatus[index]?.message || ""}{" "}
                            {/* Show Error/Success Message */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className='flex items-end justify-end col-span-2 gap-3 mt-10'>
                    <Button
                      type='button'
                      value={isProcessing ? "Importing..." : "Import"}
                      icon={"CheckCircleIcon"}
                      onClick={handleProcessUpload}
                    />
                    {isProcessed && (
                      <button
                        className='flex items-center px-2 py-1 mt-5 text-green-500 bg-white border border-green-500 rounded-full shadow hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50'
                        onClick={() => setShowBulkUploadModal(false)}
                      >
                        <FaCheck className='w-5 h-5 mr-2' /> Done
                      </button>
                    )}
                  </div>
                  <p className='mt-3 text-right text-red-600'>
                    After importing the publications, you need to click the{" "}
                    <span className='text-green-600'>
                      {" "}
                      edit icon{" "}
                      <FaRegEdit className='inline w-6 text-green-600' />
                    </span>
                    corresponding to each publication and fill-up the mandatory
                    fields.
                  </p>
                </div>
              )}
              <button
                className='flex items-center px-2 py-1 mt-5 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                onClick={() => setShowBulkUploadModal(false)}
              >
                <XIcon className='w-5 h-5 mr-2' /> Cancel
              </button>
            </div>
          </Modal>
        )}

        <div className='p-2 border bg-blue-50'>
          <TableComponent
            headers={headers}
            data={journalPublications}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

export default JournalPublication;
