import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Button from "../../../../Components/Utils/Button";
import Modal from "../../utils/Modal";
import FormPage from "./FormPage";
import BaseApi from "../../../../Http/BaseApi";

const Expertise = () => {
  // State variables
  const [isEditing, setIsEditing] = useState(false);
  const [expertise, setExpertise] = useState(); // Array to store qualifications

  const [initialValues, setInitialValues] = useState({
    broad_area: "",
    specializations: [""],
  });

  const validationSchema = Yup.object({
    broad_area: Yup.string().required("Broad Area is required"),
    // specializations: Yup.array().of(
    //   Yup.string().required("Specialization is required")
    // ),
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await BaseApi().get("/profile/expertises");

      setInitialValues({
        broad_area: response.data.data.broad_area,
        specializations: response.data.data.specializations.map(
          (spec) => spec.specialization
        ),
      });
      setExpertise(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values) => {
    console.log("Form data submitted: ", values);
    try {
      // Update form data with new values
      const { broad_area, specializations } = values;
      await BaseApi().post("/profile/expertises", values);
      console.log("Updating form data with: ", broad_area, specializations);

      // Exit edit mode
      setIsEditing(false);
      getData();
      handleCancel();
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <>
      <div className='space-y-5'>
        <div className='flex items-center justify-between p-3 mt-2 text-xl font-normal text-center uppercase border bg-blue-50'>
          <h1>Expertise : </h1>
          <Button
            type='edit'
            value='Edit'
            icon={"PencilIcon"}
            onClick={handleEdit}
          />
        </div>
        {isEditing && (
          <Modal onClose={handleCancel} isOpen={isEditing}>
            <FormPage
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </Modal>
        )}
        <div className='p-6 bg-white border border-gray-300'>
          <div className='p-4 mb-4 text-blue-600 bg-blue-100 rounded-md'>
            <h3 className='text-lg font-semibold'>Broad Area</h3>
            <p>{expertise?.broad_area}</p>
          </div>
          <div className='p-4 mb-4 text-blue-600 rounded-md bg-blue-50'>
            <h4 className='font-semibold text-md'>
              Specialization/Area of Research
            </h4>
            {expertise?.specializations.map((spec, index) => (
              <div
                key={index}
                className='p-4 text-blue-600 rounded-md bg-blue-50'
              >
                <p>{spec.specialization}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Expertise;
