import React, { useRef } from "react";
import { Formik, Form } from "formik";
import Button from "../../../../Components/Utils/Button";
import CustomSelect from "../../../../Components/Utils/CustomSelect";
import Input from "../../../../Components/Utils/Input";
import { XIcon } from "@heroicons/react/outline";

const FormPage = ({
  initialValues,
  validationSchema,
  onSubmit,
  onCancel,
  isEditMode,
}) => {
  console.log("Form is called with initialValues:", initialValues);
  const formikRef = useRef(null);
  const programTypeTypes = [
    { id: 1, name: "Conference" },
    { id: 2, name: "Workshop" },
    { id: 3, name: "Seminar" },
    { id: 4, name: "FDP" },
    { id: 5, name: "Other" },
  ];
  const roleTypes = [
    { id: 1, name: "Chairperson" },
    { id: 2, name: "Coordinator/Convener" },
    { id: 3, name: "Joint Coordinator/ Co-Coordinator" },
  ];
  const indexedInTypes = [
    { id: 1, name: "Scopus" },
    { id: 2, name: "Web of Science(core)" },
    { id: 3, name: "Web of Science (others)" },
    { id: 4, name: "Both Scopus and WOS" },
  ];
  const publishedTypes = [
    { id: 1, name: "Yes" },
    { id: 2, name: "No" },
  ];
  const monthTypes = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];

  return (
    <>
      <h1 className='flex flex-col items-center justify-center p-3 mt-2 text-xl font-normal uppercase '>
        Programme Organized
        <span className='text-sm text-red-600'>
          Conference/Seminar/Workshop/FDP etc.
        </span>
      </h1>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className='grid grid-cols-3 gap-3 p-5 '>
            <CustomSelect
              defaultInputValue={initialValues.program_type}
              options={programTypeTypes}
              name='program_type'
              label={
                <div className='flex items-center'>
                  Type of the Programme
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "program_type",
                  selectedOption.name
                );
              }}
            />

            {values.program_type === "Other" && (
              <>
                <Input
                  type='text'
                  name='other_program_type'
                  label={
                    <div className='flex items-center'>
                      Other Programme
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
              </>
            )}

            <Input
              type='text'
              name='programme'
              label={
                <div className='flex items-center'>
                  Title of the Programme
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
            />
            <CustomSelect
              defaultInputValue={initialValues.role}
              options={roleTypes}
              name='role'
              label={
                <div className='flex items-center'>
                  Role
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue("role", selectedOption.name);
              }}
            />

            <Input
              label={
                <div className='flex items-center'>
                  From Date
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              name='from_date'
              type='date'
              placeholder='From Date'
            />

            <Input
              label={
                <div className='flex items-center'>
                  To Date
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              name='to_date'
              type='date'
              placeholder='To Date'
            />

            <CustomSelect
              defaultInputValue={initialValues.published}
              options={publishedTypes}
              name='published'
              label={
                <div className='flex items-center'>
                  Whether any proceeding published?
                  <span className='ml-1 text-red-600'>*</span>
                </div>
              }
              isSearchable={true}
              getOptionValue={(option) => `${option.id}`}
              getOptionLabel={(option) => `${option.name}`}
              onChange={async (selectedOption) => {
                formikRef.current?.setFieldValue(
                  "published",
                  selectedOption?.name
                );
              }}
            />

            {values.published === "Yes" && (
              <>
                <Input
                  type='text'
                  name='proceeding_title'
                  label={
                    <div className='flex items-center'>
                      Title of the Proceeding
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
                <CustomSelect
                  defaultInputValue={
                    monthTypes.find((month) => month.id == initialValues.month)
                      ?.name
                  }
                  options={monthTypes}
                  name='month'
                  label={
                    <div className='flex items-center'>
                      Month of Publication
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                  isSearchable={true}
                  getOptionValue={(option) => `${option.id}`}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={async (selectedOption) => {
                    formikRef.current?.setFieldValue(
                      "month",
                      selectedOption.id
                    );
                  }}
                />
                <Input
                  type='text'
                  name='year'
                  label={
                    <div className='flex items-center'>
                      Year of Publication
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
                <Input
                  type='text'
                  name='editor_name'
                  label={
                    <div className='flex items-center'>
                      Editor Name with Affiliation
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
                <Input
                  type='text'
                  name='issn_isbn'
                  label={
                    <div className='flex items-center'>
                      ISSN/ISBN
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />
                <Input
                  type='text'
                  name='publisher_name'
                  label={
                    <div className='flex items-center'>
                      Publisher Name
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                />

                <CustomSelect
                  options={indexedInTypes}
                  name='indexed_in'
                  label={
                    <div className='flex items-center'>
                      Indexed in
                      <span className='ml-1 text-red-600'>*</span>
                    </div>
                  }
                  isSearchable={true}
                  getOptionValue={(option) => `${option.id}`}
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={async (selectedOption) => {
                    console.log("Selected option:", selectedOption);
                    formikRef.current?.setFieldValue(
                      "indexed_in",
                      selectedOption.name
                    );
                    console.log(
                      "Formik values after update:",
                      formikRef.current?.values
                    );
                  }}
                />
              </>
            )}

            <div className='flex justify-between col-span-3 mt-10'>
              <button
                className='flex items-center px-2 py-1 text-red-500 bg-white border border-red-500 rounded-full shadow hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50'
                onClick={onCancel}
              >
                <XIcon className='w-5 h-5 mr-2' /> Cancel
              </button>
              {isEditMode ? (
                <Button type='submit' value='Update' icon={"SaveIcon"} />
              ) : (
                <Button type='submit' value='Save' icon={"SaveIcon"} />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPage;
